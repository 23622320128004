import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import styled from 'styled-components'
import { colors } from '../styling/constants'

interface JournalProps {
  data: GatsbyTypes.JournalQuery
  pageContext: {
    locale: string
  }
}

const Wrapper = styled.div`
  h5 {
    color: ${colors.gold};
  }
  max-width: 50rem;
`

const Ingress = styled.blockquote`
  padding: 0;
  margin: 0 0 2rem 0 !important;
`

const CoverImage = styled(Img)`
  max-width: 40rem;
  margin-bottom: 2rem;
  height: auto;
`

const Journal: React.FC<JournalProps> = ({
  data: { subpage },
  pageContext: { locale },
}) => (
  <LocaleManager locale={locale}>
    <SEO
      title={subpage?.title ?? 'Journal'}
      description={subpage?.shortDescription && subpage?.shortDescription}
    />
    <div className="content extra-top">
      <Wrapper className="markdown-content">
        <CoverImage fluid={subpage?.image?.fluid} alt="Journal image" />
        <h5>{subpage?.publishedDate}</h5>
        <h3>{subpage?.title}</h3>
        <Ingress>{subpage?.shortDescription}</Ingress>
        {subpage?.content?.content &&
          documentToReactComponents(JSON.parse(subpage.content.content))}
      </Wrapper>
    </div>
  </LocaleManager>
)

export const query = graphql`
  query Journal($id: String, $locale: String) {
    subpage: contentfulJournal(id: { eq: $id }) {
      title
      shortDescription
      publishedDate(formatString: "DD MMMM, YYYY", locale: $locale)
      content {
        content
      }
      image {
        fluid(maxWidth: 500) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default Journal
